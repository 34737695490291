import { Button, Modal } from "antd";
import React, { useState } from "react";
import { dispatchtoast, getLocalStorageItem } from "../../../Utils";
import { getPayoutAndDmtOtp } from "../../../ApiServices/Apifun";
import { Images } from "../../../Controller";
import { CopyOutlined } from "@ant-design/icons";
import ReportDrawer from "../../../Common/ReportDrawer";
import VerifyModal from "../../../Common/VerifyModal";
import { Globalendpoints } from "../../../ApiServices/global";
import OtpPayout from "./OtpPayout";
const PayModal = ({ isOpen, setIsOpen, details }) => {
  const [inputValue, setInputValue] = useState("");
  const [originalVal, setOriginalVal] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [openVerifyModal, setOpenVerifyModal] = useState(false);
  const [openReceiptDrawer, setOpenReceiptDrawer] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [otpuid, setOtpUid] = useState(null);
  const[hide,setHide]=useState(false)
  const [response, setResponse] = useState({});
  const handleOk = () => {
    setIsOpen(false);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };

  const formatNumberWithCommas = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleChange = (e) => {
    const sanitized = e.target.value.replace(/[^0-9.]/g, "");
    setOriginalVal(sanitized);
    const formattedValue = formatNumberWithCommas(sanitized);
    setInputValue(formattedValue);
  };
  const handlePay = () => {
    // setBtnLoading(true)
    setOpenVerifyModal(true);
    setHide(true);

  };
  const Content = () => {
    return (
      <>
        <div className="border-2 rounded-md w-9/12 border-dashed border-gray-500  ml-4 mr-4 text-center mt-5">
          <p className="-mt-2 bg-white w-[100px] flex justify-center items-center font-semibold m-auto text-[11px]">
            Transaction I'd
          </p>
          <p className="text-[11px] mt-2 mb-2">qwdqwd</p>
        </div>
        <div className="text-[10px] border-b-[1px] py-3 border-dashed border-gray-500 w-9/12 text-gray-400 mt-4 ">
          <div className="flex justify-between items-center">
            <p>Balance Amount</p>
            <p className="text-black">qwdwqd Rs.</p>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <Modal
        title={"Pay Amount"}
        footer={null}
        className={`shadow-normal ${hide?"hidden":""}`}
        width={300}
        maskClosable={false}
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {!showSuccess ? (
          <>
            <div className="userDetail text-center font-semibold text-[13px]">
              <p>Paying {details?.benificiary_name}</p>
              <p>{details?.benificiary_account}</p>
              <p>Banking Name: {details?.benificiary_name}</p>
            </div>
            <div className="amount mt-4  font-bold text-center">
              <p className="text-3xl">₹</p>
              <input
                onChange={(e) => handleChange(e)}
                value={inputValue}
                placeholder="0"
                className="border-none w-full text-lg text-center"
              />
            </div>
          </>
        ) : (
          <div className="mt-10">
            <img
              src={Images.success}
              className="w-28 m-auto animate-bounce h-28"
              alt="success"
            />
            <p className="text-center font-semibold text-lg">
              Transaction Successful
            </p>
            <p className="text-center font-semibold text-sm">
              Txn Id : {response}
              {/* <span><CopyOutlined className="cursor-pointer" /></span> */}
            </p>
          </div>
        )}
        <Button
          onClick={() => (showSuccess ? setIsOpen(false) : handlePay())}
          loading={btnLoading}
          disabled={!inputValue}
          className="w-full mt-28 bg-primary hover:bg-primaryhover text-bswhite rounded-sm border-none "
        >
          {showSuccess ? "Go Back" : "Pay"}
        </Button>
      </Modal>
      {openVerifyModal && (
        <OtpPayout
          isOpen={openVerifyModal}
          setIsOpen={setOpenVerifyModal}
          setShowSuccess={setShowSuccess}
          setResponse={setResponse}
          setpaymodal={setIsOpen}
          setHide={setHide}
          otherFields={{
            otp_uid: otpuid,
            latitude: getLocalStorageItem("latitude"),
            longitude: getLocalStorageItem("longitude"),
            amount:originalVal,
            ...details
          }}
          endpoint={Globalendpoints.payAmount}
        />
      )}
      {/* {openReceiptDrawer &&
      <ReportDrawer
        title={"Payout Receipt"}
        open={openReceiptDrawer}
        setOpen={setOpenReceiptDrawer}
        content={<Content />}
      />
} */}
    </>
  );
};

export default PayModal;
