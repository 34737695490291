import React, { useState } from "react";
import { Images } from "../../../Controller";
import { Button, Tooltip } from "antd";
import { CaretRightOutlined, DeleteFilled } from "@ant-design/icons";
import { beneficiaryDelete } from "../../../ApiServices/Apifun";
import VerifyModal from "../../../Common/VerifyModal";
import { Globalendpoints } from "../../../ApiServices/global";
import AddBeneFiciaryModal from "./AddBeneFiciaryModal";
import DmtPayModal from "./DmtPayModal";
import { BsFillTrash2Fill } from "react-icons/bs";
import { GiCancel } from "react-icons/gi";
import { trimString } from "../../../Utils";

const BeneficiaryCard = ({ remitterDetails, mobile, handleSearch }) => {
  const [showBtn, setShowBtn] = useState({
    show: false,
    index: null,
  });
  const [response, setResponse] = useState(false);
  const [openVerifyModal, setOpenVerifyModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [bebenficiaryId, setBeneFiciaryId] = useState(null);
  const [PayBeneOpen, setPayBeneOpen] = useState({ show: false, detail: null });
  const [btnLoading, setBtnLoading] = useState(false);
  const handleConfirm = (data) => {
    setBeneFiciaryId(data.id);
    setBtnLoading(true);
    const formdata = new FormData();
    formdata.append("remitter_mobile", remitterDetails.remiiter_mobile);
    formdata.append("beneficiary_id", data.id);
    beneficiaryDelete(formdata)
      .then((res) => {
        if (res.status) {
          setShowBtn({ show: false, index: null });
          setResponse(res.data);
          setOpenVerifyModal(true);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <>
      <div className="flex justify-end items-center mt-2 flex-wrap">
        <p
          onClick={() => setIsOpen(true)}
          className="bg-primary cursor-pointer hover:bg-primaryhover p-3 shadow-normal rounded-3xl text-bswhite"
        >
          Add Beneficiary
        </p>
      </div>
      <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 mt-3 lg:grid-cols-3 gap-4 place-items-center">
        {remitterDetails &&
          remitterDetails.beneficiaries.length !== 0 &&
          remitterDetails?.beneficiaries.map((i, index) => {
            return (
              <div
                key={index}
                className="bg-primary h-[200px] relative animate-fade-in  w-full py-3 rounded-2xl "
              >
                <div className="text-white ml-8 mb-4">
                  <img src={Images.circleShape} alt="circle" />
                  <Tooltip title={i.name} arrow={false}>
                    <p className="text-md md:text-xl mt-1 cursor-pointer">
                      {trimString(i.name, 15)}
                    </p>
                  </Tooltip>
                  <p className="text-md">Acc No. {i.account}</p>
                  <p className="text-md mt-3">IFSC: {i.ifsc}</p>
                </div>

                <p className="absolute top-4 right-6 text-white text-lg cursor-pointer">
                  <DeleteFilled
                    onClick={() => setShowBtn({ show: !showBtn.show, index })}
                  />
                </p>
                <div
                  onClick={() => setPayBeneOpen({ show: true, detail: i })}
                  className=" hover:shadow-2xl hover:bg-green-600  hover:text-white w-1/4 md:w-1/4 absolute bottom-0 right-0 float-right transition-all cursor-pointer   flex justify-around i gap-x-4 p-2 bg-bswhite rounded-tl-3xl"
                >
                  <p className="font-semibold">Pay</p>
                </div>
                {showBtn.show && showBtn.index === index && (
                  <div className="flex absolute bottom-4 animate-slide-in mt-14 gap-4 justify-start items-center">
                    <Button
                      onClick={() => setShowBtn({ show: false, index: null })}
                      className="border-none ml-4 bg-bswhite font-semibold hover:bg-red-600 hover:text-white transition-all"
                    >
                      <GiCancel />
                    </Button>
                    <Button
                      loading={btnLoading}
                      onClick={() => handleConfirm(i)}
                      className="border-none bg-bswhite flex justify-center items-center  font-semibold hover:bg-green-600 hover:text-white transition-all"
                    >
                      <BsFillTrash2Fill />
                    </Button>
                  </div>
                )}
              </div>
            );
          })}
      </div>
      {openVerifyModal && (
        <VerifyModal
          isOpen={openVerifyModal}
          setIsOpen={setOpenVerifyModal}
          apicall={handleSearch}
          otherFields={{
            otp_refrence_id: response.otprefrenceid,
            beneficiary_id: bebenficiaryId,
            remitter_mobile: mobile,
          }}
          endpoint={Globalendpoints.verifyBeneficiaryDelete}
        />
      )}
      {isOpen && (
        <AddBeneFiciaryModal
          mobile={mobile}
          isOpen={isOpen}
          handleSearch={handleSearch}
          setIsOpen={setIsOpen}
        />
      )}
      {PayBeneOpen.show && (
        <DmtPayModal
          referenceKey={remitterDetails.reference_key}
          mobile={mobile}
          isOpen={PayBeneOpen.show}
          setIsOpen={setPayBeneOpen}
          beneficiaryId={PayBeneOpen.detail}
          details={remitterDetails}
        />
      )}
    </>
  );
};

export default BeneficiaryCard;
