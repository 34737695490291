import {
    Button,
    DatePicker,
    Select,
    Skeleton,
    Spin,
    Table,
    Tag,
    Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { Images } from "../../../Controller";
import { useDispatch } from "react-redux";
import { setselectedTab } from "../../../Store/B2bslice";




const DmtPaymentGateway = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setselectedTab("11.989"))
    }, []);
    return (
        <>
            <div className="">
                <div className="prepaid  animate-fade-in w-full  bg-white shadow-normal  rounded-xl p-4">
                    <div className="flex justify-between items-center">
                        <p className="font-bold text-[15px] mb-2">Dmt Payment Gateway</p>
                        <img src={Images.comingSoon} alt="" />
                    </div>

                </div>
            </div>


        </>
    );
};

export default DmtPaymentGateway;
