import { useDispatch, useSelector } from "react-redux";
import { getLocalStorageItem } from "../../../../Utils";
import { setselectedTab } from "../../../../Store/B2bslice";
import { useEffect } from "react";
import { APIURL } from "../../../../ApiServices/Axios";

const AepsCredo = ({ iframeRef }) => {
  const dispatch = useDispatch();
//https://aeps.ezulix.co
  // Access Redux state
  const profiledetail = useSelector((state) => state.B2B.profileDetail);

  // Dispatch action on component load
  useEffect(() => {
    dispatch(setselectedTab("117"));
  }, [dispatch]);

  // Get the base URL dynamically
  const baseURL = window.location.origin;
  const apiUrl = APIURL;
  // Construct the `iframe` URL
  const accessToken = getLocalStorageItem("access_token");
  const latitude = getLocalStorageItem("latitude");
  const longitude = getLocalStorageItem("longitude");
  const encodedBaseURL = btoa(baseURL); // Encode the base URL
  const encodedApiURL = btoa(apiUrl); // Encode the base URL

  const iframeSrc =
    profiledetail.credo_aeps_onboard === 0
      ? `https://aeps.ezulix.co/member_panel/#/banking/aeps2-onboard/${accessToken}/${latitude}/${longitude}/${encodedApiURL}`
      : profiledetail.credo_aeps_status === 0 && profiledetail.credo_aeps_onboard === 1
        ? `https://aeps.ezulix.co/member_panel/#/banking/waiting/${accessToken}`
        : `https://aeps.ezulix.co/member_panel/#/banking/aeps2-2fa/${accessToken}/${latitude}/${longitude}/${encodedBaseURL}/${encodedApiURL}`;

  return (
    <iframe
      ref={iframeRef}
      id="iframe-3000"
      className="w-full h-full"
      src={iframeSrc}
      title="AEPS Credo"
    />
  );
};

export default AepsCredo;
