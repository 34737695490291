import { Button, Form } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { addRemitters } from "../../../ApiServices/Apifun";
import VerifyModal from "../../../Common/VerifyModal";
import { Globalendpoints } from "../../../ApiServices/global";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setselectedTab } from "../../../Store/B2bslice";
import { aadharPattern, mobilePattern, pincodePattern } from "../../../Utils";
import CommonInput from "../../../Common/CommonInput";

const RemitterReg = () => {
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form.useForm();
  const formref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [response, setResponse] = useState({});
  const memberServices = useSelector((state) => state.B2B.memberServices);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setselectedTab("4"));
    if (!memberServices.includes("4")) {
      navigate("/not-Available");
    }
  }, []);
  const handleAdd = (val) => {
    setBtnLoading(true);
    const formdata = new FormData();
    Object.entries(val).forEach(([key, value]) => formdata.append(key, value));
    addRemitters(formdata)
      .then((res) => {
        if (res.status) {
          setResponse(res.data);
          setIsOpen(true);
          //  navigate("/dmt/verifyOtp",{state:{mobile:fields.mobile,otp_refrence_id:res.data.otprefrenceid}})
        }
      })
      .catch((err) => console.log(err))
      .finally((err) => setBtnLoading(false));
  };
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-1 place-items-start">
        <div className="prepaid animate-slide-in w-full lg:w-1/2 bg-white shadow-normal  rounded-3xl p-5">
          <p className="font-bold text-[15px] mb-2">Add Remitter</p>
            <Form
              name="basic"
              form={form}
              ref={formref}
              onFinishFailed={(err) => console.log(err)}
              onFinish={(val) => handleAdd(val)}
            >
              <Form.Item
                name={`mobile`}
                label={"Mobile Number"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter Mobile Number!",
                  },
                  {
                    pattern: mobilePattern,
                    message: "Please enter a valid mobile number",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <CommonInput
                  placeholder="Enter Your Mobile Number!"
                  name="mobile"
                />
              </Form.Item>{" "}
              <Form.Item
                name={`aadhaar_number`}
                label={"Aadhaar Number"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter aadhaar mo.!",
                  },
                  {
                    pattern: aadharPattern,
                    message: "Please enter valid aadhaar",
                  },
                ]}
              >
                <CommonInput
                 onInput={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  e.target.value = value;
                }}
                  placeholder="Enter Your First Name!"
                  name="first_name"
                />
              </Form.Item>{" "}
              <Form.Item
                name={`pin_code`}
                label={"Pin Code"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter PinCode!",
                  },
                  {
                    pattern: pincodePattern,
                    message: "Please enter a valid pin-code",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <CommonInput placeholder="Enter PinCode!" name="pin_code" />
              </Form.Item>{" "}
              <Form.Item>
                <Button
                  htmlType="submit"
                  loading={btnLoading}
                  className="bg-primary mt-2 w-full border-none rounded-md h-9 text-center text-white flex justify-center items-center font-bold text-md cursor-pointer"
                >
                  Submit
                </Button>
              </Form.Item>{" "}
            </Form>
          </div>
        </div>
      {isOpen && (
        <VerifyModal
          isOpen={isOpen}
          from="register"
          setIsOpen={setIsOpen}
          endpoint={Globalendpoints.verifyRemitterOtp}
          otherFields={{
            mobile: form.getFieldValue("mobile"),
            otp_refrence_id: response.otprefrenceid,
          }}
        />
      )}
    </>
  );
};

export default RemitterReg;
