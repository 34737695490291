import { Button, Form } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  aadharPattern,
  emailPattern,
  getLocalStorageItem,
  mobilePattern,
  panPattern,
} from "../../../Utils";
import { setselectedTab } from "../../../Store/B2bslice";
import { addFund, submitKyc } from "../../../ApiServices/Apifun";
import { useDispatch, useSelector } from "react-redux";
import VerifyModal from "../../../Common/VerifyModal";
import { Globalendpoints } from "../../../ApiServices/global";
import CommonInput from "../../../Common/CommonInput";
import { useNavigate } from "react-router-dom";

const AddFund = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const formref = useRef();
  const dispatch = useDispatch();
  const checkKyc = useSelector((state) => state.B2B.profileDetail);
  const [isOpen, setIsOpen] = useState(false);
  const [paymentUrl, setpaymentUrl] = useState(false);
  const [btnLoading,
     setBtnLoading] = useState(false);
  useEffect(() => {
    if (checkKyc.dmt_kyc === "1") {
      navigate("/dmt/remitters");
    }
  }, [checkKyc]);

  

  const handleSubmit = (val) => {
    setBtnLoading(true);
    const formdata = new FormData();
    formdata.append("latitude", getLocalStorageItem("latitude"));
    formdata.append("longitude", getLocalStorageItem("longitude"));
    Object.entries(val).map(([key, value]) =>
      formdata.append(key, value)
    );
    addFund(formdata)
      .then((res) => {
        if (res.status) {
            window.open(res.PaymentUrl, "_blank");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  useEffect(() => {
    dispatch(setselectedTab("209"));
  }, []);
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 place-items-start">
        <div className="prepaid w-full animate-slide-in bg-white rounded-3xl  shadow-normal  p-5">
          <p className="font-bold text-[15px] mb-2">Add Fund</p>
          <div className="selectionFields ">
            <Form
              name="basic"
              form={form}
              ref={formref}
              onFinishFailed={(err) => console.log(err)}
              onFinish={(val) => handleSubmit(val)}
            >
              <Form.Item
                name={`amount`}
                label={"Enter Amount"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter Enter Amount!",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <CommonInput
                  placeholder="Enter Your Enter Amount"
                  name="mobile"
                />
              </Form.Item>{" "}
           
     
              <Form.Item>
                <Button
                  htmlType="submit"
                  loading={btnLoading}
                  className="bg-primary mt-2 w-full border-none rounded-md h-9 text-center text-white flex justify-center items-center font-bold text-md cursor-pointer"
                >
                  Add Fund
                </Button>
              </Form.Item>{" "}
            </Form>
          </div>
        </div>
      </div>
      {/* {isOpen && (
        <VerifyModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          endpoint={Globalendpoints.verifyKycOtp}
          otherFields={{}}
        />
      )} */}
    </>
  );
};

export default AddFund;
