import { Button, Input, Pagination, Select, Spin, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { MemberList } from "../../../ApiServices/Apifun";
import { AiOutlineSearch } from "react-icons/ai";
import { setselectedTab } from "../../../Store/B2bslice";
import { useDispatch, useSelector } from "react-redux";
import { trimString } from "../../../Utils";
import { useNavigate } from "react-router-dom";
import CommonInput from "../../../Common/CommonInput";

const MemberLists = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [current, setCurrent] = useState(1);
  const [searchKeyword, setSearchKeyWord] = useState("");
  const [searchcolumn, setSearchColumn] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [totalPages, setTotalPages] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [searchOptions, setSearchOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const profiledetail = useSelector((state) => state.B2B.profileDetail);

  const handleEdit = (record) => {
    console.log("Edit record:", record);
    // Navigate to edit page or open modal
    navigate(`/add-member/${record.id}`);
  };


  const ReportColumn = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (text) => <p className="font-medium w-[50px]">{text}</p>,
    },
    {
      title: "Member Id",
      dataIndex: "member_id",
      key: "member_id",
      render: (text) => <p className="font-medium w-[80px]">{text}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <p style={{ width: "100px" }} className="font-medium">
          {text}
        </p>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      render: (text) => <a>{text ?? "-"}</a>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => (
        <p className="font-medium w-[180px] overflow-y-scroll">
          {text ? text : "NAN"}
        </p>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => (
        <p className="font-medium w-[120px] overflow-y-scroll">
          {text ? text : "NAN"}
        </p>
      ),
    },
    {
      title: "Parent",
      dataIndex: "parent",
      key: "parent",
      render: (text) => (
        <p className="font-medium w-[120px] overflow-y-scroll">
          {text ?? "-"}
        </p>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text) => (
        <Tooltip title={text}>
          <p className="font-medium w-[150px] overflow-y-scroll">
            {text ? trimString(text) : "NAN"}
          </p>
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <>
          {text === "1" ? (
            <Tag color="green">Approved</Tag>
          ) : text === "2" ? (
            <Tag color="red">Rejected</Tag>
          ) : text === "0" ? (
            <Tag color="red">Inactive</Tag>
          ) : (
            <Tag color="yellow">Pending</Tag>
          )}
        </>
      ),
    },
    {
      title: "Remark",
      dataIndex: "member_note",
      key: "member_note",
      render: (text) => (
        <p style={{ width: "100px" }} className="font-medium">
          {text ?? "-"}
        </p>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        record.status == 2 ?
          <Button
            type="primary"
            onClick={() => handleEdit(record.id)}
            className="bg-blue-500"
          >
            Edit
          </Button>
          :
          "-"
      ),
    },
  ];


  const getMemberList = (
    search_keyword = searchKeyword,
    search_column = searchcolumn ?? "",
    from_date = "",
    to_date = ""
  ) => {
    setLoading(true);
    const payload = {
      per_page: pageSize,
      page_no: current,
      search_keyword: search_keyword,
      search_column: search_column,
      from_date: from_date,
      to_date: to_date,
    };
    const formdata = new FormData();
    Object.keys(payload).map((key) => formdata.append(key, payload[key]));
    MemberList(formdata)
      .then((res) => {
        setDataSource(res.data);
        setTotalPages(res.total);
        setSearchOptions(
          res?.filter_column_name?.map((i, index) => {
            return {
              label: i,
              value: res.filter_column_key[index],
            };
          })
        );
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    dispatch(setselectedTab("15"));
    if (profiledetail.member_type === "Retailer") {
      navigate("/dashboard");
    } else {
      getMemberList();
    }
  }, [current, pageSize]);
  useEffect(() => {
    dispatch(setselectedTab("15"));
  }, []);

  return (
    <>
      <div className="">
        <div className="flex justify-end items-center ">
          <p onClick={() => navigate("/add-member")} className="mb-2 bg-primary hover:bg-primaryhover text-bswhite p-3 rounded-md  cursor-pointer ">
            Add Member
          </p>
        </div>
        <div className="prepaid  animate-fade-in w-full  bg-white shadow-normal  rounded-xl p-4">
          <div className="flex justify-between items-center">
            <p className="font-bold text-[15px] mb-2">Member Lists</p>
            <p
              onClick={() => {
                getMemberList("", "", "", "");
                setSearchKeyWord("");
                setSearchColumn(null);
                setFromDate("");
                setToDate("");
              }}
              className="font-medium text-xs cursor-pointer bg-bsred hover:bg-red-600 text-white px-2 py-1 rounded-md text-[15px] mb-2"
            >
              Clear Filter
            </p>
          </div>
          <div className="selectionFields grid grid-cols-1 md:grid-cols-3 gap-4 place-items-center ">
            <div className="w-full">
              <p className={`required text-textGray mt-2`}>
                Select Search Type
              </p>
              <Select
                placeholder="Select search type"
                value={searchcolumn}
                onChange={(val) => setSearchColumn(val)}
                className="w-full mt-2"
                options={searchOptions}
              />
            </div>
            <div className="w-full">
              <p className={`required text-textGray `}>Keyword</p>

              <CommonInput
                placeholder="Seacrh"
                value={searchKeyword}
                onChange={(e) => setSearchKeyWord(e.target.value)}
                className="outline-none text-xs border mt-4 w-full  border-gray-200 focus:border-primary   px-2 "
              />
            </div>
          </div>
          <div className="w-full flex justify-end items-center mt-3">
            <Button
              // disabled=
              onClick={() => getMemberList()}
              style={{ color: "white" }}
              className="flex justify-between gap-2 items-center font-semibold hover:bg-primaryhover bg-primary border-none"
            >
              <AiOutlineSearch />
              <p>Search</p>
            </Button>
          </div>
        </div>
      </div>
      <div>
        <div className="mt-4">
          <Spin spinning={loading}>
            <Table
              className="history-table animate-fade-in max-h-[500px]  overflow-x-auto text-white rounded-lg"
              columns={ReportColumn}
              pagination={false}
              onRow={(record, rowIndex) => {
                return {
                  className: "cursor-pointer text-[12px]",
                };
              }}
              dataSource={dataSource}
            />
            <div className="mt-2 mb-8 flex justify-end items-center">
              <Pagination
                defaultPageSize={pageSize}
                showQuickJumper={false}
                defaultCurrent={current}
                current={current}
                onShowSizeChange={(a, b) => setPageSize(b)}
                total={totalPages}
                onChange={(page, pageString) => setCurrent(page)}
              />
            </div>
          </Spin>
        </div>
      </div>
    </>
  );
};

export default MemberLists;
