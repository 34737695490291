import {
    Button,
    DatePicker,
    Input,
    Select,
    Skeleton,
    Spin,
    Table,
    Tooltip,
  } from "antd";
  import React, { useEffect, useState } from "react";
  import { formatDateAndTime, setLocalStorageItem, trimString } from "../../../Utils";
  import { getdmtWalletReport } from "../../../ApiServices/Apifun";
  import dayjs from "dayjs";
  import ReportDrawer from "../../../Common/ReportDrawer";
  import { AiOutlineSearch } from "react-icons/ai";
import { setselectedTab } from "../../../Store/B2bslice";
import { useDispatch } from "react-redux";
import CommonInput from "../../../Common/CommonInput";
import CommonPagination from "../../../Common/Pagination";
  
  const DmtWalletReport = () => {
    const dispatch=useDispatch()
    const [dataSource, setDataSource] = useState([]);
    const [current, setCurrent] = useState(1);
    const [searchKeyword, setSearchKeyWord] = useState("");
    const [searchcolumn, setSearchColumn] = useState(null);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [totalPages, setTotalPages] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const[openReceiptDrawer,setOpenReceiptDrawer]=useState(false)
    const[result,setResult]=useState(null)
    const [searchOptions, setSearchOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const ReportColumn = [
      {
        title: "Date",
        dataIndex: "Date",
        key: "Date",
        render: (text) => (
          <p className="font-medium w-[150px]">{text}</p>
        ),
      },
      {
        title: "Transaction Id",
        dataIndex: "transaction_id",
        key: "transaction_id",
        render: (text) => {
          return (
            <>
              <p className=" font-medium  overflow-y-scroll">{text}</p>
            </>
          );
        },
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (text) => (
          <p style={{ width: "80px" }} className=" font-medium">
            {text} Rs.
          </p>
        ),
      },
      {
        title: "Balance",
        dataIndex: "balance",
        key: "balance",
        render: (text) => (
          <p style={{ width: "80px" }} className=" font-medium">
            {text} Rs.
          </p>
        ),
      },
      {
        title: "Type",
        dataIndex: "amount_type",
        key: "amount_type",
        render: (text) => (
          <div className="flex justify-start gap-2 items-center">
            <div
              className={`w-4 h-4 ${
                text === "Cr" ? "bg-green-600" : "bg-red-700"
              } rounded-md`}
            ></div>
            <a className={`${text === "Cr" ? "text-green-600" : "text-red-700"}`}>
              {text}
            </a>
          </div>
        ),
      },
      {
        title: "Remarks",
        dataIndex: "remarks",
        key: "remarks",
        render: (text) => {
          return (
            <>
             <Tooltip title={text}>
             <p className="font-medium w-[200px] overflow-y-scroll">
                {text ? text : "NAN"}
              </p>
             </Tooltip>
            </>
          );
        },
      },
    ];
  
    const getReport = (
      search_keyword = searchKeyword,
      search_column = searchcolumn,
      from_date = fromDate,
      to_date = toDate
    ) => {
      setLoading(true);
      const payload = {
        per_page: pageSize,
        page_no: current,
        search_keyword: search_keyword,
        search_column: search_column,
        from_date: from_date,
        to_date: to_date,
      };
      const formdata = new FormData();
      Object.keys(payload).map((key) => formdata.append(key, payload[key]));
      getdmtWalletReport(formdata)
        .then((res) => {
          setDataSource(res.data);
          setTotalPages(res.total);
          setSearchOptions(
            res?.filter_column_name?.map((i, index) => {
              return {
                label: i,
                value: res.filter_column_key[index],
              };
            })
          );
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    };
    useEffect(() => {
      getReport();
    }, [current, pageSize]);
    useEffect(() => {
      dispatch(setselectedTab("11.89"));
    }, []);
    const Content = () => {
      return (
        <>
          <div className="border-2 rounded-md w-9/12 border-dashed border-gray-500  ml-4 mr-4 text-center mt-5">
            <p className="-mt-2 bg-white w-[100px] flex justify-center items-center font-semibold m-auto text-[11px]">
              Transaction I'd
            </p>
            <p className="text-[11px] mt-2 mb-2">
              {result?.transaction_id}
            </p>
          </div>
          <div className="text-[11px] border-b-[1px] py-3 border-dashed border-gray-500 w-9/12 text-gray-600 mt-4 ">
            <div className="flex justify-between items-center">
              <p>Transaction Date</p>
              <p className="text-black">{result?.Date??"-"}</p>
            </div>
          </div>
          <div className="text-[11px] border-b-[1px] py-[6px] border-dashed border-gray-500 w-9/12 text-gray-600 mt-2 ">
            <div className="flex justify-between items-center">
              <p>Amount</p>
              <p className="text-black">{result?.amount}</p>
            </div>
          </div>
          <div className="text-[11px] border-b-[1px] py-[6px] border-dashed border-gray-500 w-9/12 text-gray-600 mt-2 ">
            <div className="flex justify-between items-center">
              <p>Type</p>
              <p className="text-black">{result?.amount_type}</p>
            </div>
          </div>
          <div className="text-[11px] border-b-[1px] py-[6px] border-dashed border-gray-500 w-9/12 text-gray-600 mt-2 ">
            <div className="flex justify-between items-center">
              <p>Remark</p>
              <p className="text-black">{result?.remarks}</p>
            </div>
          </div>
        </>
      );
    };
    return (
      <>
        <div className="">
          <div className="prepaid  animate-fade-in w-full  bg-white shadow-normal  rounded-xl p-4">
            <div className="flex justify-between items-center">
              <p className="font-bold text-[15px] mb-2">Dmt Wallet Report</p>
              <p
                onClick={() => {
                  getReport("", "", "", "");
                  setSearchKeyWord("");
                  setSearchColumn(null);
                  setFromDate("");
                  setToDate("");
                }}
                className="font-medium text-xs cursor-pointer bg-bsred hover:bg-red-600 text-white px-2 py-1 rounded-md text-[15px] mb-2"
              >
                Clear Filter
              </p>
            </div>
            <div className="selectionFields grid grid-cols-1 md:grid-cols-3 gap-4 place-items-center ">
              <div className="w-full">
                <p className={`required text-textGray mt-2`}>
                  Select Search Type
                </p>
                <Select
                  placeholder="Select search type"
                  value={searchcolumn}
                  onChange={(val) => setSearchColumn(val)}
                  className="w-full mt-2"
                  options={searchOptions}
                />
              </div>
              <div className="w-full">
                <p className={`required text-textGray `}>Keyword</p>
                {searchcolumn === "wallet.created_at" ? (
                  <DatePicker
                    // value={dayjs("2023-09-07","YYYY-MM-DD")}
                    popupClassName="bottom-calendar"
                    allowClear
                    // value={searchKeyword}
                    className="w-full datepicker mt-4"
                    onChange={(val) =>
                      setSearchKeyWord(dayjs(val).format("YYYY-MM-DD"))
                    }
                    placement="bottomLeft"
                    placeholder="Search"
                  />
                ) : (
                  <CommonInput
                    placeholder="Seacrh"
                    value={searchKeyword}
                    onChange={(e) => setSearchKeyWord(e.target.value)}
                    className="outline-none text-xs border mt-4 w-full  border-gray-200 focus:border-primary   px-2 "
                  />
                )}
              </div>
              <div className="w-full">
                <p className={`required text-textGray mt-2`}>
                  From Date - To Date
                </p>
                <DatePicker.RangePicker
                  allowClear
                  onChange={(val) => {
                    setFromDate(val ? dayjs(val[0]).format("YYYY-MM-DD") : "");
                    setToDate(val ? dayjs(val[1]).format("YYYY-MM-DD") : "");
                  }}
                  className="w-full mt-2"
                />
              </div>{" "}
            </div>
            <div className="w-full flex justify-end items-center mt-3">
              <Button
                // disabled=
                onClick={() => getReport()}
                style={{ color: "white" }}
                className=" font-semibold flex justify-between gap-2 items-center hover:bg-primaryhover bg-primary border-none"
              >
               <AiOutlineSearch/><p>Search</p>
              </Button>
            </div>
          </div>
        </div>
        <div>
          <div className="mt-4">
            <Spin spinning={loading}>
              <Table
                className="history-table animate-fade-in max-h-[500px]  overflow-x-auto text-white rounded-lg"
                columns={ReportColumn}
                pagination={false}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      setResult(record)
                      setOpenReceiptDrawer(true)
                    },
                    className:"cursor-pointer text-[12px]"
                  };
                }}
                dataSource={dataSource}
              />
              <div className="mt-2 mb-8 flex justify-end items-center">
              <CommonPagination totalPages={totalPages} current={current} pageSize={pageSize} setCurrent={setCurrent} setPageSize={setPageSize}/>
              </div>
            </Spin>
          </div>
        </div>
        {openReceiptDrawer && (
          <ReportDrawer
            title={"Dmt Wallet Receipt"}
            open={openReceiptDrawer}
            setOpen={setOpenReceiptDrawer}
            content={<Content />}
          />
        )}
      </>
    );
  };
  
  export default DmtWalletReport;
  